import React from 'react';

const Medidor: React.FC = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2978 16C32.2978 24.6076 25.1654 31.6 16.3489 31.6C7.53239 31.6 0.4 24.6076 0.4 16C0.4 7.39245 7.53239 0.4 16.3489 0.4C25.1654 0.4 32.2978 7.39245 32.2978 16Z"
      fill="white"
      stroke="#536789"
      strokeWidth="0.8"
    />
    <rect
      x="10.4"
      y="8.80005"
      width="11.52"
      height="14.4"
      rx="1.6"
      stroke="#536789"
      strokeWidth="1.2"
    />
    <rect
      x="12.32"
      y="11.6801"
      width="7.68"
      height="1.92"
      rx="0.4"
      fill="#536789"
    />
    <circle cx="13.28" cy="16.48" r="0.96" fill="#536789" />
    <circle cx="13.28" cy="19.36" r="0.96" fill="#536789" />
    <circle cx="16.16" cy="16.48" r="0.96" fill="#536789" />
    <circle cx="16.16" cy="19.36" r="0.96" fill="#536789" />
    <circle cx="19.04" cy="16.48" r="0.96" fill="#536789" />
    <circle cx="19.04" cy="19.36" r="0.96" fill="#536789" />
  </svg>
);

export default Medidor;
