import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageProps, navigate } from 'gatsby';
import { navigateToNextView, postOptions, handleFetch } from '@/utils';

import { BenefitsText, Spacer } from '@/components/generics/styled';
import DataVerify from '@/components/verificar/dataVerify';
import Footer from '@/components/generics/footer';
import Button from '@/components/generics/button';
import Header from '@/components/header';
import Layout from '@/components/layout';

type LocationState = {
  mideUser: MideCustomer;
  formData: UserData;
};

const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CorrectedBenefitsText = styled(BenefitsText)`
  margin: 24px 16px;
  text-align: left;
`;

const sendRecharge = async (queryData: any): Promise<any> => {
  const res = await handleFetch('', postOptions(queryData));
  return res;
};

const Verificar: React.FC<PageProps<null, null, LocationState>> = ({
  location,
}) => {
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (
      !location.state?.formData.email ||
      !location.state?.formData.phone ||
      !location.state?.formData.medidor
    ) {
      navigate('/');
    }
  }, []);
  if (
    !location.state?.formData.email ||
    !location.state?.formData.phone ||
    !location.state?.formData.medidor
  ) {
    return null;
  }
  const { email, phone, medidor } = location.state.formData;
  const handleOnClick = async () => {
    try {
      setDisable(true);

      await sendRecharge({
        mide_id: medidor,
        phoneNumber: phone,
        mail: email,
        amount: 50,
      });
      navigateToNextView({
        state: { email, phone, medidor },
      });
    } catch {
      navigate('/error', {
        state: {
          message:
            'El regalo Tap es válido una única vez por medidor. Compartilo a tus conocidos para que ellos puedan aprovecharlo!',
        },
      });
    }
  };

  return (
    <Layout>
      <Header />
      <Main>
        <CorrectedBenefitsText>
          Confirmá que los datos estén correctos
        </CorrectedBenefitsText>
        <DataVerify
          mideUser={location.state?.mideUser}
          formData={location.state?.formData}
        />
        <Spacer space={50} />
        <Button onClick={handleOnClick} disabled={disable}>
          Confirmar
        </Button>
      </Main>
      <Footer />
    </Layout>
  );
};

export default Verificar;
