import React from 'react';

const Location: React.FC = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2978 16C32.2978 24.6076 25.1654 31.6 16.3489 31.6C7.53239 31.6 0.4 24.6076 0.4 16C0.4 7.39245 7.53239 0.4 16.3489 0.4C25.1654 0.4 32.2978 7.39245 32.2978 16Z"
      fill="white"
      stroke="#536789"
      strokeWidth="0.8"
    />
    <path
      d="M16.4 8.80005C13.5327 8.80005 11.2 11.1702 11.2 14.0835C11.2 16.9671 14.428 21.4588 15.7999 23.2376C16.1082 23.6374 16.6965 23.6337 17.0002 23.2303C18.3643 21.4186 21.6 16.8313 21.6 14.0835C21.6 11.1702 19.2674 8.80005 16.4 8.80005ZM17.969 15.6305C17.5363 16.07 16.9682 16.2897 16.4 16.2897C15.8319 16.2897 15.2636 16.07 14.8311 15.6305C13.966 14.7516 13.966 13.3214 14.8311 12.4423C15.2501 12.0165 15.8073 11.782 16.4 11.782C16.9927 11.782 17.5498 12.0166 17.969 12.4423C18.8341 13.3214 18.8341 14.7516 17.969 15.6305Z"
      stroke="#536789"
      strokeWidth="1.2"
    />
  </svg>
);

export default Location;
