import React from 'react';

const Mail: React.FC = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2978 16C32.2978 24.6076 25.1654 31.6 16.3489 31.6C7.53239 31.6 0.4 24.6076 0.4 16C0.4 7.39245 7.53239 0.4 16.3489 0.4C25.1654 0.4 32.2978 7.39245 32.2978 16Z"
      fill="white"
      stroke="#536789"
      strokeWidth="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45455 9.59998H22.5455C23.3488 9.59998 24 10.2367 24 11.0222V20.9778C24 21.7632 23.3488 22.4 22.5455 22.4H9.45455C8.65122 22.4 8 21.7632 8 20.9778V11.0222C8 10.2367 8.65122 9.59998 9.45455 9.59998ZM9.45469 14.306V20.9777H22.5456V14.3065L16.0003 17.5064L9.45469 14.306ZM9.45469 12.7159L16.0004 15.9163L22.5456 12.7164V11.0221H9.45469V12.7159Z"
      fill="#536789"
    />
  </svg>
);

export default Mail;
