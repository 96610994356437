import React from 'react';
import styled from 'styled-components';

import Medidor from '@/components/generics/svg/medidor';
import Location from '@/components/generics/svg/location';
import Mail from '@/components/generics/svg/mail';

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
`;

const MideItem = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #f3f6fa;
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
`;

const DataText = styled.span`
  color: #002350;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
`;

const DataTitle = styled(DataText)`
  font-weight: 600;
`;

const DataInfo = styled(DataText)``;

const WrappedIcon = styled.div`
  margin: 12px;
  width: 32px;
  height: 32px;
`;

type DataVerifyProps = {
  mideUser: MideCustomer;
  formData: UserData;
};

const DataVerify: React.FC<DataVerifyProps> = ({ mideUser, formData }) => {
  const { MeterNumber, StreetNumber, StreetName, MunicipalityName } =
    mideUser.customer;

  return (
    <Wrapper>
      <MideItem>
        <WrappedIcon>
          <Medidor />
        </WrappedIcon>
        <DataTitle>N° de medidor:&nbsp;</DataTitle>
        <DataInfo>{MeterNumber}</DataInfo>
      </MideItem>
      <Divider />
      <MideItem>
        <WrappedIcon>
          <Location />
        </WrappedIcon>
        <DataTitle>Dirección:&nbsp;</DataTitle>
        <DataInfo>
          {StreetName} {StreetNumber}, {MunicipalityName}
        </DataInfo>
      </MideItem>
      <Divider />
      <MideItem>
        <WrappedIcon>
          <Mail />
        </WrappedIcon>
        <DataTitle>Mail:&nbsp;</DataTitle>
        <DataInfo>{formData.email}</DataInfo>
      </MideItem>
    </Wrapper>
  );
};

export default DataVerify;
